.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.95),
    rgba(245, 245, 245, 0.95)
  );
  backdrop-filter: blur(5px);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 1000;
  font-size: 11px;
  letter-spacing: 0.3px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  color: #666;
}

.version {
  font-family: monospace;
  color: #888;
}

.divider {
  color: #ddd;
  font-weight: 200;
}

.company-info {
  display: flex;
  gap: 4px;
}

.company-link,
.email-link {
  color: #4a6fa5;
  text-decoration: none;
  transition: color 0.2s ease;
}

.company-link:hover,
.email-link:hover {
  color: #2c4162;
  text-decoration: underline;
}

.copyright {
  margin-left: 12px;
  color: #999;
}

/* Hover effect for the entire footer */
.footer:hover {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.98),
    rgba(245, 245, 245, 0.98)
  );
}

/* Animation for links */
.company-link,
.email-link {
  position: relative;
}

.company-link::after,
.email-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -1px;
  left: 0;
  background-color: #4a6fa5;
  transition: width 0.3s ease;
}

.company-link:hover::after,
.email-link:hover::after {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    margin-bottom: 60px;
    position: static;
    padding: 12px 0;
  }

  .footer-content {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 8px;
  }

  .divider {
    display: none;
  }

  .copyright {
    width: 100%;
    margin: 8px 0 0 0;
    text-align: center;
  }
}

/* Very small screens */
@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    gap: 6px;
  }

  .company-info {
    flex-direction: column;
    align-items: center;
  }
}

/* Print styles */
@media print {
  .footer {
    position: fixed;
    bottom: 0;
    border-top: 1px solid #eee;
    background: none;
    backdrop-filter: none;
  }
}