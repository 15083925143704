.pos-system {
    max-width: 1800px;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .pos-layout {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    margin-left: 80px;
    margin-right: 20px;
  }

  .sales-tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .inventory-tab {
    padding: 15px 30px;
    margin: 0 10px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
  }
  
  .inventory-tab.active {
    background-color: #0095da; 
    color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);  /* Slightly stronger shadow for active tab */
  }
  
  .document-button {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
    background: none !important;
    border: none;
    padding: 0;
    font: inherit;
  }
  
  
  /* Categories Section */
  .categories-section {
    width: 200px;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
  }

  
  
  .category-button {
    background-color: #055083 !important;
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    text-align: left;
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Menu Section */
  .menu-section {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff;
    overflow-y: auto;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .item-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    background-color: white;
  }
  
  .item-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .item-card h3 {
    margin: 5px 0;
  }
  
  .item-card button {
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Cart Section */
  .cart-section {
    width: 300px;
    padding: 20px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
  }
  
  .student-select {
    margin-bottom: 20px;
  }
  
  .student-details {
    margin-top: 10px;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
  }
  
  .cart-items {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
  }
  
  .cart-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .item-details {
    flex-grow: 1;
  }
  
  .cart-item h4 {
    margin: 0;
  }
  
  .cart-item button {
    background-color: #dc3545 !important;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cart-summary {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
  }
  
  .subtotal, .tax, .total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .total {
    font-weight: bold;
  }
  .vat {
    margin-top: 20px;
   margin-bottom: 20px; 
  }
  .payment {
   margin-top: 20px;
   margin-bottom: 20px; 
  }
  
  .pay-button {
    width: 100%;
    padding: 10px;
    background-color: #28a745 !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Responsive design */
  @media (max-width: 1200px) {
    .pos-layout {
      flex-direction: column;
    }
  
    .categories-section {
      width: 100%;
      height: auto;
    }
  
    .menu-section, .cart-section {
      width: 100%;
    }
  }