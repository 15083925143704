.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.form-content {
  width: 100%;
  max-width: 1000px; /* Adjust as needed */
}

.create-user-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section {
  padding: 30px;
  margin-bottom: 20px;
  border: 2px solid #005082; 
  border-radius: 10px;
}

.section h3 {
  color: #fff;
  background-color: #005082; 
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin: -30px -30px 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* This will help to wrap the form groups if there is not enough space */
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px; /* Adjust as needed */
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 10px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #5B5FC7;
  outline: none;
}

.submit-button {
  padding: 15px 20px;
  background-color: #005181;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
  align-self: center;
}

.submit-button:hover {
  background-color: #005181;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

input::placeholder,
textarea::placeholder {
  color: #aaa;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 750px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.user-details-summary {
  margin-top: 20px;
}

.user-details-summary p {
  margin: 5px 0;
}

button {
  background-color: #005181;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #005181;
}

.error-message {
  color: red;
  margin-top: 10px;
}


.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  flex: 1; /* Makes the input take up as much space as possible */
}

.password-toggle-icon {
  margin-left: 10px;
  cursor: pointer;
}

.generate-password-btn {
  background-color: #a4a4a4; 
  color: white; 
  padding: 5px;
  border: none; 
  border-radius: 4px; 
  font-size: 10px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  margin-left: 10px; 
}

.generate-password-btn:hover {
  background-color: #383c3f; 
}


.checkbox-group {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between elements */
  margin-bottom: 20px;
}

.custom-checkbox-label {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: #555;
}

.custom-checkbox-input {
  margin-right: 10px;
  width: 20px;  /* Larger checkbox size */
  height: 20px;
  accent-color: #4A90E2; /* Custom color */
}

.language-container {
  display: flex;
  align-items: center;
  gap: 15px;  
  background-color: #def3ff;
  border-radius: 5px;
  padding: 10px;
}

.language-container label {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between checkbox and label */
  font-size: 1.1em;
  color: #575757;
}

.language-container input[type="checkbox"] {
  margin-right: 5px;
  width: 18px;  /* Customize size */
  height: 18px; /* Customize size */
  accent-color: #4A90E2; /* Custom color for checkbox */
}
