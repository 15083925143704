 /* Teacher Hours */
 .teacher-hours-container {
    width: 92%;
    display: flex;
    margin-left: 80px;
    margin-top: 80px;
    background-color: #fff;
    border-radius: 0px 15px 15px 15px;
  }


  .teacher-details-container {
    display: flex;
    margin-top: 70px;
    margin-left: 80px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 15px 15px 15px 15px;
  }
  
  .teacher-hours-content {
    margin: 0px 20px 60px 0px;
    padding: 20px;
    flex-grow: 1;
  }
  
  .teacher-hours-content h1 {
    margin-bottom: 20px;
  }
  
  .teacher-hours-content input {
    margin-right: 10px;
    padding: 5px;
    width: 100px;
  }
  
  .teacher-hours-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .teacher-hours-content th, .teacher-hours-content td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    overflow: hidden; /* Prevent content overflow */
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  
  .teacher-hours-content th {
    background-color: #383838;
    color: #fff;
    white-space: nowrap;
  }
  
  .teacher-hours-content button {
    padding: 5px 10px;
    margin: 0 2px;
    border: none;
    background-color: #e7e7e7;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.875rem;
  }
  
  .teacher-hours {
    margin: 0px 20px 60px 20px;
    flex-grow: 1;
  }

  .teacher-hours th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    overflow: hidden; /* Prevent content overflow */
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #ddd;
    padding: 3px;
    font-size: 14px;
  }

  .teacher-hours input {
    margin-right: 10px;
    padding: 5px;
  }
  
  .teacher-hours table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .teacher-hours button {
    padding: 5px 10px;
    margin: 0 2px;
    border: none;
    background-color: #e7e7e7;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.875rem;
  }

  .teacher-hours th {
    white-space: nowrap;
  }

  .table-container {
    width: 100%; /* Full screen width */
    overflow-x: auto; /* Enables horizontal scrolling */
    margin: 0 auto; /* Center alignment */
  }
  
  .ant-table {
    min-width: 1200px; /* Ensures table content doesn't squeeze */
  }
  
  .ant-table-container {
    overflow-x: scroll; /* Ant Design Table internal scrolling */
  }
  
  
  .datepicker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px; /* Space between items */
    padding: 10px 0;
  }
  
  .datepicker label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .datepicker select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .datepicker button {
    padding: 5px 10px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px; /* Space between icon and text */
  }
  
  
  .confirmation-popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
  }

  .teacher-payment-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  }
  
  .teacher-payment-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    text-align: center;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .close-modal:hover {
    color: #000;
  }
  
  /* Status Classes */
.status-approved {
  background-color: #337ab7;
  color: white;
}

.status-pending {
  background-color: #be6464;
  color: white;
}

/* Payout Classes */
.payout-paid {
  background-color: #658d51;
  color: white;
}

.payout-pending {
  background-color: #be6464;
  color: white;
}

.admin-status-approved {
  background-color: #005181;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}

.admin-status-pending {
  background-color: none;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}
.admin-status-pending button{
  background-color: #0095da;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}


/* Teacher Payment Report */
.teacher-payment-container {
  width: 92%;
  display: flex;
  margin-left: 80px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0px 15px 15px 15px;
}

.teacher-payment-content {
  margin: 20px;
  flex-grow: 1;
}

.teacher-payment-content h1 {
  margin-bottom: 20px;
}

.teacher-payment-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.teacher-payment-content th, .teacher-payment-content td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Prevents text from wrapping inside table cells */
}

.teacher-payment-content th {
  background-color: #4CAF50;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.teacher-payment-content tr:hover {
  background-color: #f5f5f5;
}

.teacher-payment-content td {
  background-color: #fafafa;
  font-weight: 500;
}

.approved-text {
  color: rgb(113, 113, 113);
  font-style: italic;
}


  /* Tabs container */
.tutor-tabs-container {
  display: flex;
  margin-top: 70px;
  margin-left: 80px;
}

/* Individual tab button */
.tutor-tab-button {
  padding: 10px 20px;
  border-bottom: none;
  background-color: #f0f0f0 !important;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
}

.tutor-tab-button:hover {
  background-color: #0095da!important;
  color: white !important;
}

/* Active tab styling */
.tutor-tab-button.active {
  background-color: white !important;
  border-bottom: 2px solid white;
  color: #333 !important;
}

/* Unselected tab styling */
.tutor-tab-button:not(.active) {
  color: #666;
}

/* Add some margin between tabs */
.tutor-tab-button + .tab-button {
  margin-left: -1px; /* To avoid double border */
}

/* Responsive Styling for Mobile Devices */
@media (max-width: 768px) {
  .teacher-hours-container,
  .teacher-details-container,
  .teacher-payment-container {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    border-radius: 15px; /* Rounded corners for mobile */
    flex-direction: column; /* Stack items vertically */
  }

  .teacher-payment-content,
  .teacher-hours-content {
    margin: 20px;
    padding: 15px;
    flex-grow: 1;
    width: 100%;
  }

  .teacher-hours th,
  .teacher-hours td {
    font-size: 12px; /* Smaller font for mobile */
  }

  .teacher-payment-content table {
    font-size: 12px; /* Adjust table font for smaller screens */
  }

  .tutor-tabs-container {
    margin-left: 0;
    margin-top: 10px;
  }

  .tutor-tab-button {
    font-size: 14px !important;
    padding: 8px 15px;
  }

  .teacher-hours-content h1,
  .teacher-payment-content h1 {
    font-size: 16px; /* Adjust heading font size for mobile */
  }

  .teacher-payment-modal-content {
    width: 95%;
    max-width: none; /* Remove max-width constraint */
    padding: 15px;
  }
}

/* Common Styles */
.teacher-hours-content h1 {
  margin-bottom: 20px;
}

.teacher-hours-content input {
  margin-right: 10px;
  padding: 5px;
  width: 100px;
}

.teacher-hours-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.teacher-hours-content th,
.teacher-hours-content td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teacher-hours-content th {
  background-color: #383838;
  color: #fff;
  white-space: nowrap;
}

.teacher-hours-content button {
  padding: 5px 10px;
  margin: 0 2px;
  border: none;
  background-color: #e7e7e7;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.875rem;
}

.teacher-payment-container {
  width: 92%;
  display: flex;
  margin-left: 80px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0px 15px 15px 15px;
}

.teacher-payment-content {
  margin: 20px;
  flex-grow: 1;
}

.teacher-payment-content h1 {
  margin-bottom: 20px;
}

.teacher-payment-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.teacher-payment-content th,
.teacher-payment-content td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.teacher-payment-content th {
  background-color: #4CAF50;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.teacher-payment-content tr:hover {
  background-color: #f5f5f5;
}

.teacher-payment-content td {
  background-color: #fafafa;
  font-weight: 500;
}

/* Tabs container */
.tutor-tabs-container {
  display: flex;
  margin-top: 70px;
  margin-left: 80px;
}

/* Individual tab button */
.tutor-tab-button {
  padding: 10px 20px;
  border-bottom: none;
  background-color: #f0f0f0 !important;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
}

.tutor-tab-button:hover {
  background-color: #0095da !important;
  color: white !important;
}

/* Active tab styling */
.tutor-tab-button.active {
  background-color: white !important;
  border-bottom: 2px solid white;
  color: #333 !important;
}

/* Add some margin between tabs */
.tutor-tab-button + .tab-button {
  margin-left: -1px; /* To avoid double border */
}