.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #055083;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
}

.company-logo {
  height: 60px; /* Reduced height for the logo on all screens */
}

.user-info-and-signout {
  display: flex;
  align-items: center;
  gap: 10px; /* Reduced gap to better fit on smaller screens */
}

.user-info {
  color: white;
  font-size: 18px; 
  margin-right: 20px;
}

.user-icon {
  margin-right: 5px;
}
.chat-btn {
  background: #1faf35 !important;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
}

.chat-icon {
  font-size: 18px;
  color: #fff;
}

.notification-badge {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  min-width: 18px;
  text-align: center;
}
.profile-popup {
  position: absolute;
  top: 60px;
  right: 20px;
  padding: 20px;
  background: #fff;
  color: #333;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.profile-popup.show {
  opacity: 1;
  visibility: visible;
}

.profile-content {
  text-align: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.doodle-header-btn {
  background: #deba1d !important;
  border: none;
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.doodle-icon {
  font-size: 20px;
  color: #fff;
}

.doodle-header-btn:hover {
  color: #588641 !important; 
}

.change-password-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
}

.change-password-form {
  margin-top: 20px;
  text-align: left;
}

.change-password-form label {
  display: block;
  margin-bottom: 10px;
}

.change-password-form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.change-password-buttons {
  display: flex;
  justify-content: center;
}

.sign-out-btn {
  padding: 5px 8px; /* Adjust padding to fit better on small screens */
  background-color: #0095da;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size to save space */
}

.sign-out-btn:hover {
  background-color: #0095da;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .header {
      padding: 0 15px;
  }

  .company-logo {
      height: 56px; /* Even smaller on medium and smaller screens */
  }
}

@media (max-width: 768px) {
  .header {
      justify-content: space-between;
  }

  /* No change in display for user info and sign out on small screens */
  .user-info-and-signout {
      display: flex;
  }
}

@media (max-width: 480px) {
  .header {
      padding: 0 10px;
  }

  .company-logo {
      height: 60px; /* Smallest size for very small screens */
  }

  /* Optional: You might want to hide some elements if it's too cramped */
  .user-info {
      display: none; /* Hide only the text info if space is very limited */
  }
}
