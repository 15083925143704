/* General styling for the authentication container */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../public/theplannerbg.jpg');
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
}

.auth-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #055083; /* Overlay color */
  opacity: 0.6; /* Adjust the opacity for a transparent overlay */
  z-index: 1; /* Ensure the overlay is behind the content */
}

.auth-container > * {
  position: relative; /* Ensure the content is above the overlay */
  z-index: 2;
  margin: 25px
}

  /* Styling for the form container */
  .form-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #055083;
    color: #ffffff;
    padding: 2rem;
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* More prominent shadow */
    width: 90%;
    max-width: 400px; /* Adjusted width */
    margin: 20px;
    animation: fadeIn 0.5s ease-in-out; /* Fade-in animation */
  }

 .form-container2 h2 {
  color:#fff;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
 }

 .form-container2 span {
  color:#fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
 }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  
  /* Grid layout for form fields */
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group.full-width {
    grid-column: span 2; /* Full width fields span both columns */
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #cccccc !important; /* Light gray text for labels */
  }
  
  .form-group input {
    display: inline-block;
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.858); /* Semi-transparent input background */
    color: #333;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Styling for the terms container */
  .terms-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #6e777d;
    margin-bottom: 10px;
  }
  
  .terms-checkbox {
    margin-right: 10px; /* Increased margin for better spacing */
  }
  
  /* Styling for the button container */
  .button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #0095da;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  button:hover {
    background-color: #0095da;
    transform: translateY(-3px);
  }
  
  button:active {
    background-color: #005181;
    transform: translateY(0);
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    margin-top: 5px;
    font-size: 14px;
  }
  
  /* Reset password link styling */
  .reset-container {
    margin-top: 20px;
  }
  
  .reset-text {
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    padding: 5px 10px;
    border: none;
    background-color: transparent;
    outline: none;
  }
  
  .reset-text:hover, .reset-text:focus {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 5px;
    transform: scale(1.05);
  }
  
  .reset-text:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) inset;
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1);
  }
  
  /* Sign-in link styling */
  .signin {
    font-size: 14px;
    text-decoration: underline;
    color: #ffffff;
    cursor: pointer;
    display: block;
    margin: 20px auto 0;
    text-align: center;
  }
  
  .signin:hover {
    text-decoration: none;
    color: #ffffff;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .form-container {
      padding: 1rem;
      width: 80%;
    }
  
    .form-grid {
      grid-template-columns: 1fr; /* Single column on smaller screens */
    }
  
    h2 {
      font-size: 1.25rem;
    }
  
    .form-group label,
    .form-group input,
    .button-container button {
      margin-top: 0.5rem;
      padding: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .form-container {
      width: 95%;
      padding: 1rem;
    }
  
    .form-grid {
      grid-template-columns: 1fr; /* Single column on very small screens */
    }
  
    /* Further adjustments for very small screens */
    h2 {
      font-size: 1.1rem;
    }
  }
  