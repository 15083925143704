.left-panel {
  width: 70px;
  background-color: #fff;
  color: #055083;
  border-right: 1px solid #cfcfcf;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 2;
  overflow: hidden;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin-top: 4rem;
  overflow-y: auto;
  height: calc(100% - 6.5rem);
}

.menu-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  color: inherit;
  width: 100%;
  text-decoration: none;
}

.menu-link:hover, .menu-link.active {
  background-color: #cfcfcf;
  color: #055083;
  border-radius: 50px 0 0 50px;
}

.menu-icon {
  font-size: 18px;
  width: 30px;
  text-align: center;
  margin-bottom: 5px;
}

.menu-text {
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .left-panel {
    width: 60px;
  }
  .menu-link:hover, .menu-link.active {
    background-color: #cfcfcf;
    color: #055083;
    border-radius: 50%; /* Change border-radius to 50% to make it circular */
  }
}

@media (max-width: 480px) {
  .left-panel {
    width: 100%;
    height: 70px;
    top: auto;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #055083
  }

  .menu-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    padding: 0;
    margin: 0;
    height: auto;
  }

  .menu-link {
    flex-direction: row;
    justify-content: center;
    width: auto;
    padding: 10px;
  }
  .menu-link:hover, .menu-link.active {
    background-color: #055083;
    color: #fff;
    border-radius: 10px;
    padding: 10px;

  }

  .menu-icon {
    margin-bottom: 0;
    font-size: 22px;
    padding: 4px;
  }

  .menu-text {
    display: none;
  }
}
