.classroom-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 20px 70px 90px;
}


@media (max-width: 768px) {
    .classroom-page-container {
        margin: 0;
        margin-top: 60px;
    }

}
