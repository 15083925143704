.main-content,
.appointment-container,
.calendar-content,
.dashboard-wrapper 
.admincontainer {
  width: 100%;
}

.dashboard-container,
.admin-dashboard-container
.admincontainer {
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.dashboard-content {
  flex-direction: column;
  width: 93%;
  margin: 70px 0 60px 80px;
}

.main-content {
  flex-direction: column;
  width: 93vw;
  height: fit-content;
  margin-left: 80px;
}

.main-content-full {
  flex-direction: column;
  width: 93vw;
  height: fit-content;
  margin-left: 80px;
}

/* Appointment Container */
.appointment-container .calendar-content {
  width: 100vw;
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: max-width 0.3s ease;
}

/* Admin Dashboard */
  
  .stats-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
  }
  
  .stat-box {
    background-color: #005181;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    flex: 1 1;
    font-size: 20px;
    color: #fff;
  }
  
  
  .stat-box:hover
  {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  
  .icon-and-text {
  display: flex;
  align-items: center;
  
  }
  
  .icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  }
  
  .stat-box:nth-child(1) .icon-circle {
    background-color: #CC3F0C;

  }
  
  .stat-box:nth-child(2) .icon-circle {
    background-color: #33673B;
  }
  
  .stat-box:nth-child(3) .icon-circle {
    background-color: #b9a80a;
  }
  
  .stat-box:nth-child(4) .icon-circle {
    background-color: #3a4a90;
  }
  
  .fa-icon {
  font-size: 1.5em;
  color: #fff;
  }
  
  .text-content {
  display: flex;
  flex-direction: column;
  }
  
  .stat-box h3 {
  margin: 0;
  color: #fff;
  font-weight: bold;
  }
  
  .stat-box p {
  font-size: 1.9em;
  color: #FC6D6D;
  font-weight: bold;
  }
  
  .stat-box .percentage-change {
  margin-top: 10px;
  font-size: 0.9em;
  color: #fff;
  }
  
  .stat-box .positive-change {
  color: #28a745;
  }
  
  .stat-box .negative-change {
  color: #dc3545;
  }
  
  .fa-icon {
  font-size: 1.5em;
  color: #fff;
  }
  
  .classes-calendar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
  }
  
  .list-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .classes-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .admin-class-card {
    background-color: #fff;
    padding: 15px;
    border-bottom: 2px solid #055083;
    width: 90%; /* Adjust the width as needed */
  }
  
  .admin-class-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .admin-class-card-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .admin-class-info {
    font-size: 0.9em;
    color: #555;
  }
  
  /* List box styling */
  .list-box {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 49%; 
    box-sizing: border-box;
  }

  
  .list-box h2 {
  margin-bottom: 20px;
  color: #be6464;
  font-weight: bold;
  font-size: 1.5em;
  }
  
  .classes-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
  }
  
  .classes-table th,
  .classes-table td {
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  }
  
  .classes-table th {
  background-color: #f7f7f7;
  color: #343a40;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  }
  
  .classes-table td {
  font-size: 0.9em;
  color: #555;
  }
  
  .classes-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  
  .classes-table tr
  {
  background-color: #f1f1f1;
  }
  
  .status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-left: 10px;
  }
  
  .status.active {
  background-color: #d1fae5;
  color: #28a745;
  }
  
  .status.inactive {
  background-color: #fef08a;
  color: #ffc107;
  }
  
  .status.offline {
  background-color: #f8d7da;
  color: #dc3545;
  }
  
  .shortcut-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
  }
  
  .shortcut-button {
    background: #eef7ff; /* Dark green background */
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  color: #337ab7;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.225);
  border-radius: 12px;
  border-left: 5px solid #337ab7;
  font-size: 1em;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  }
  
 
  .shortcut-button span {
  margin-top: 10px;
  font-weight: bold;
  }

  .shortcut-button:hover
  {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .calendar-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 48%;
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .calendar-box:hover
  {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
  .admin-dashboard-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  }

  .classes-cards {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-content {
    flex-direction: column;
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .stats-container {
    flex-direction: column; /* Stack stat boxes vertically */
    align-items: center;
    width: 90%;
  }

  .stat-box {
    width: 90%; /* Make stat boxes take full width on mobile */
    margin: 10px 0; /* Add vertical margin for spacing */
  }
  
  .classes-calendar-container {
  flex-direction: column;
  align-items: center;
  }
  

  .list-container {
  flex-direction: column;
  align-items: center;
  }
  
  .list-box,
  .list-box.active-classes-box {
  width: 90%;
  }

  
  }
  


/* Tabs container */
.main {
  height: fit-content;
  margin-top: 70px;
}
.tabs-container {
  display: flex;
  margin-top: 70px;
  margin-left: 80px;
}

/* Individual tab button */
.tutor-tab-button {
  padding: 10px 20px;
  border-bottom: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 17px !important;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  gap: 3px;
}
.tutor-tab-button:hover {
  background-color: #0095da !important;
  color: white !important;
}

/* Active tab styling */
.tutor-tab-button.active {
  background-color: white;
  border-bottom: 2px solid white;
  color: #333;
}

/* Unselected tab styling */
.tutor-tab-button:not(.active) {
  color: #666;
}

/* Add some margin between tabs */
.tutor-tab-button + .tutor-tab-button {
  margin-left: 2px; /* To avoid double border */
}

/* Content styling */
.main-content {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0 15px 15px 15px;
  width: 90%;
}

/* Icon circle styling */
.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  width: 40px;
  height: 40px;
}


.warning p{
  padding: 5px;
  background-color: #f8d7da !important;
  color: #721c24 !important;
  border-radius: 5px;
  border: 1px solid #f5c6cb !important;
}


/* Form Container */
.form-container,
.modal-content,
.classroom-modal,
.modal-container,
.modal,
.subject-modal {
  background-color: #fff;
  color: #333;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 90%;
  max-width: 400px;
  margin: 5% auto;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 80vh; /* Set a max-height */
  overflow-y: auto;
}


.form-container input,
.form-container select,
.form-container button,
.modal-content input,
.modal-content select,
.modal-contnet textarea,
.classroom-modal input,
.classroom-modal select,
.modal-container button,
.subject-modal input,
.subject-modal textarea {
  display: block;
  width: calc(100% - 20px);
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-container button,
.modal-container button {
  background-color: #0095da;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container button:hover,
.modal-container button:hover {
  background-color: #0095da;
}

.form-group label,
.form-container label,
.modal-content label,
.classroom-modal label,
.subject-modal form label {
  display: block;
  margin-bottom: 10px;
}

.non-resizable-textarea {
  resize: none; 
  width: 100%;  
  height: 100px; 
  padding: 8px;  
  margin-bottom: 10px; 
  box-sizing: border-box; 
}

.fit-content-button {
  background-color: #0095da;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100px;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 50px;
}

.th {
  background-color: #268db9;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.td {
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
}

.tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tr:hover {
  background-color: #e9f5e9;
}

.td a {
  color: #0095da;
  text-decoration: none;
}

.td a:hover {
  text-decoration: underline;
}

.teacher-container td:first-child,
.adminstudent-container td:first-child,
.subject-table-container td:first-child,
.classroomtable-container td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.teacher-container td:last-child,
.adminstudent-container td:last-child,
.subject-table-container td:last-child,
.classroomtable-container td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.teacher-image,
.student-image {
  width: 50px;
  height: auto;
  border-radius: 50%;
}
.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-buttons button {
  margin-right: 5px;
  border: none;
  color: white;
  padding: 2px 4px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.reset-button {
  background-color: #ff4d4d !important;
  position: relative;
  padding: 5px;
  font-size: 12px;
}

.reset-button:hover {
  background-color: #e60000 !important;
}

select {
  padding: 5px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #bacee4;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.select-active {
  background-color: #8dc572; /* Green for active */
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
}

.select-inactive {
  background-color: #ccc; /* Gray for inactive */
  color: #33333390;
  border: none;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
}

.role-counts {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.role-count-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  background-color: #ffffff;
  width: 150px;
}

.task-list-table td {
  white-space: nowrap; 
}

.task-list-table td.about-column {
  white-space: normal;
  word-break: break-word; 
}
.test-analysis-container {
  padding: 20px;
  margin: auto;
}

/* Floating button styling */
.floating-button {
  position: fixed;
  top: 100px; /* Position it 80px from the top */
  right: 0; /* Position it on the right edge */
  z-index: 1010;
  background-color: #176632;
  color: white;
  border-radius: 50% 0 0 50%; /* Create a half-circle shape */
  width: 56px;
  height: 50px; /* Half the height to match the half-circle shape */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 24px;
  border: none;
  outline: none;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 0; /* Ensure no margin on the right */
}

.floating-button:hover {
  background-color: #0c2916;
  transform: scale(1.1);
}


/* Search box styling */
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  margin: 20px 0;
}

.search-container .search-icon {
  margin-right: 10px;
  color: #ccc;
}

.search-container input[type="text"] {
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}



/*Dashboard charts*/
.charts-container {
  padding: 20px;
}

/* General Styling for Test Analysis Section */
.test-analysis-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.test-analysis-container h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.test-analysis-container h3 {
  font-size: 20px;
  color: #444;
  margin-bottom: 10px;
}

.test-analysis-chart {
  padding: 15px;
  margin-bottom: 20px;
}

.test-analysis-chart h3 {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
  text-align: center; /* This centers the text horizontally */
}

/* Responsive Layouts */
@media (max-width: 768px) {
  .admin-dashboard-container {
    padding: 10px;
  }

  .test-analysis-container h2 {
    font-size: 20px;
  }

  .test-analysis-container h3 {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .admin-dashboard-container {
    padding: 5px;
  }

  .test-analysis-container h2 {
    font-size: 18px;
    text-align: center;
  }

  .test-analysis-container h3 {
    font-size: 16px;
    text-align: center;
  }

  .test-analysis-chart {
    padding: 10px;
  }
}

/* Ensure all containers are mobile-responsive */
.test-analysis-chart {
  width: 100%;
}

/* Additional improvements for mobile viewing */
@media (max-width: 768px) {
  .test-analysis-chart {
    margin-bottom: 15px;
  }
}


/* Role-based background colors */
.role-superadmin,
.role-admin,
.role-manager,
.role-teacher,
.role-student,
.role-sales,
.role-user {
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 12px;
  white-space: nowrap;
}

.role-superadmin {
  background-color: #055083;
}

.role-admin {
  background-color: #FF9800;
}

.role-manager {
  background-color: #b9a80a;
}

.role-teacher {
  background-color: #33673b;
}

.role-student {
  background-color: #cc3f0c;
}

.role-sales {
  background-color: #903a8d;
}

.role-user {
  background-color: #d3d3d3;
  color: black;
}
.dashboard-button {
  background-color: #073611c3 !important;
  padding: 3px 19px;
}

.performance-button {
  background-color: #863359c3 !important;
  padding: 3px 19px;

}

.custom-confirm-alert .react-confirm-alert-body h1 {
  font-size: 14px; /* Adjust the font size for the title */
}

.custom-confirm-alert .react-confirm-alert-body p {
  font-size: 12px; /* Adjust the font size for the message */
}

/* Modal styling */
.modal-backdrop,
.classroom-modal-backdrop,
.subject-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}


.modal-header {
  color: white;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  font-size: 1.25em;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header button {
  background-color: white;
  color: #4A7729;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.modal-header button:hover {
  background-color: #e6e6e6;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-actions button {
  background-color: #0095da ;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  flex: 1;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.modal-actions button:hover {
  background-color: #0095da;
}

.modal-close-icon,
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  border: none;
  background: transparent;
}

.modal-close-button {
  color: #333;
}

.teacher-details p {
  margin: 10px 0;
}

.teacher-image-large {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.link-button {
  background: none;
  border: none;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  background-color: #055083;
}


/* Create Subject Button Styling */
.create-subject-button {
  background-color: #4A7729;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  transition: background-color 0.3s ease;
}

.create-subject-button:hover {
  background-color: #3a5d1e;
}



/* Responsive adjustments */
@media (max-width: 768px) {
 
  .dashboard-container,
  .main-content,
  .tabs-container,
  .teacher-container table,
  .adminstudent-container table,
  .subject-table-container table,
  .classroomtable-container table {
    width: 100%;
  }

  .main-content {
    flex-direction: column;
  }

  .tab span,
  .form-container label,
  .form-container input,
  .form-container select,
  .form-container button,
  .modal-content input,
  .modal-content select,
  .classroom-modal input,
  .classroom-modal select,
  .subject-modal input,
  .subject-modal select,
  .modal-container button {
    font-size: 14px;
  }

  .icon-circle {
    width: 50px;
    height: 50px;
  }

  .tab span {
    font-size: 0.875rem;
  }

  .subject-table-container table {
    font-size: 0.875rem;
  }
  
}

@media (max-width: 480px) {
  .tabs-container {
    margin-left: 70px;
    flex-direction: row;
    align-items: center;
    gap: 2px;
  }

  .icon-circle {
    width: 50px;
    height: 50px;
  }

  .form-container,
  .modal-content,
  .classroom-modal,
  .modal-container,
  .subject-modal {
    padding: 1rem;
    width: 95%;
  }

  .form-container h2,
  .modal-container h2 {
    font-size: 1.1rem;
  }
}

/* AdminPanel.js */

/* Add this section to your existing CSS file */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.create-teacher-button, 
.create-student-button, 
.create-classroom-button,
.create-subject-button,
.add-subject-button, 
.add-classroom-button {
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  background-color: #0095da;
  color: white;
}


.create-teacher-button:hover,
.create-student-button:hover,
.create-classroom-button:hover,
.create-subject-button:hover {
  background-color: #0095da;
  transform: translateY(-3px);
}


.add-subject-button {
  background-color: #3a4a90;
  color: white;
}

.add-subject-button:hover {
  background-color: #2d3670;
  transform: translateY(-3px);
}

.add-classroom-button {
  background-color: #b9a80a;
  color: white;
}

.add-classroom-button:hover {
  background-color: #948308;
  transform: translateY(-3px);
}


/*Announcement Box*/
.announcement-admin-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.announcement-form-container {
  background: #f9f9f9; 
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.announcement-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.announcement-form input,
.announcement-form textarea {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.announcement-form textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #0095da;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0095da;
}

.announcement-list-container {
  margin-top: 10px;
}

.announcement-table {
  width: 100%;
  border-collapse: collapse;
}

.announcement-table th,
.announcement-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.announcement-table th {
  background-color: #172A60;
  color: white;
}

.announcement-table td img {
  max-width: 100px;
  height: auto;
}

.centered-content {
  text-align: center;
}

.edit-button, .delete-button, color-icon-button {
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 5px;
}

.edit-button {
  background-color: #f0ad4e !important;
  color: #fff;
}

.edit-button:hover {
  background-color: #e99d3d !important;
}

.color-icon-button {
  background-color: #0e5410 !important;
}

.delete-button {
  background-color: #be6464 !important;
  color: #fff;
}

.delete-button:hover {
  background-color: #a55353 !important;
}

.color-picker-container {
  display: flex;
  align-items: flex-start;
  justify-content: center; 
  gap: 20px; 
}

.preset-colors {
  display: grid;
  grid-template-columns: repeat(5, 30px);
  grid-gap: 5px;
  margin-left: 20px;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #fff;
}

.color-swatch:hover {
  border: 2px solid #000;
}

.save-color-button {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.save-color-icon {
  margin-right: 5px;
  padding: 5px;
}


.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.close-button {
  background-color: white !important;
  color: #333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px 10px;
}

.close-button:hover {
  background-color: #0095da;
}
