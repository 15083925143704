.subject-page {
  padding: 0 20px;
  color: #333; /* Changed to a dark color for better readability */
  padding-left: 70px;
  background-color: #f8f8f8; /* Light background for contrast */
}

/* Styling for the subject tabs */

.subject-tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 15px 20px;
  background-color: #2A4860;
  overflow-x: auto; 
  white-space: nowrap; 
}

.tab-item {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: white !important;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tab-item:not(.active):hover {
  background-color: #e9ecef;
  transform: translateY(-2px); /* Slight lift on hover */
}

.tab-item.active {
  background-color: #FF6B6B !important; 
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Lift active tab */
}


/* Container for subject cards */
.subject-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  padding-left: 20px;
}

.subject-card {
  flex: 0 1 calc(25% - 20px);
  max-width: 330px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 15px;
  background: white;
  display: flex;
  flex-direction: column;
  color: #333;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subject-icon {
  color: #005181;
  font-size: 30px;
}

.subject-card h3 {
  font-size: 1.2em;
  margin: 0;
  color: #333;
}

.card-body {
  padding: 0 20px;
}

.card-body p {
  font-size: 0.9em;
  margin: 5px 0;
  color: #666;
}

.card-footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.subject-card button {
  background-color: #0095da;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.subject-card button:hover {
  background-color: #0095da;
  transform: scale(1.05);
}

.subject-card:hover {
  box-shadow: 0 5px 15px rgba(159, 159, 159, 0.3);
  transform: translateY(-5px);
}

.modal-overlay-subject {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.modal-content-subject {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  width: 100%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  background: none !important;
  color: #333;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.image-container {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.details-container {
  width: 100%;
}

.details-container h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.8em;
  color: #333;
}

.details-container p {
  margin: 5px 0;
  font-size: 1em;
  color: #666;
}

.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.confirmation-modal-content p {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.confirmation-modal-content button {
  padding: 10px 20px;
  background-color: #0095da;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-modal-content button:hover {
  background-color: #0095da;
}

/* Add responsive styles as needed */
@media (max-width: 1024px) {
  .subject-card {
    flex: 0 1 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .subject-container {
    margin-top: 30px;
    padding-left: 10px;
  }
  .subject-card {
    flex: 0 1 calc(50% - 10px);
  }
  .subject-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 93%;
    margin-top: 60px;
    padding: 15px 20px;
    background-color: #2A4860;
    border-radius: 0px 0px 35px 35px;
    overflow-x: auto; 
    white-space: nowrap; 
  }
}

@media (max-width: 480px) {
  .subject-container {
    margin-top: 20px;
    padding: 0 10px; /* Add padding to the left and right */
  }
  .subject-card {
    flex: 0 1 calc(100%);
    width: calc(100% - 20px); /* Adjust width considering the padding */
    margin: 0 auto 20px; /* Center the card and add bottom margin */
    
  }

  .subject-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 93%;
    margin-top: 60px;
    padding: 15px 20px;
    background-color: #2A4860;
    border-radius: 0px 0px 35px 35px;
    overflow-x: auto; 
    white-space: nowrap; 
  }
  .subject-page {
    padding: 0px;
    color: #333;
    background-color: #f8f8f8;
  }
}


@media (max-width: 768px) {
  .subject-tabs {
    padding-left: 10px;
  }
  .tab-item {
    padding: 10px;
  }
}

